import * as React from "react"
import PageTemplate from "../components/pageTemplate"
import {LocationContext} from "../routes"
import Link from "../components/link"
import {useIntl} from "gatsby-plugin-intl"

const Contact = ({ location }) => {
  const intl = useIntl()
  return (
    <LocationContext.Provider value={location}>
      <PageTemplate title="">
        {intl.locale === "de" ? <DeContact /> : <EnContact />}
      </PageTemplate>
    </LocationContext.Provider>
  )
}

const EnContact: React.FC = () => {
  return (
    <div style={{ height: "68vh" }}>
      <h3 style={{ color: "#558335" }}>
        Graz Climate Change Indicators Core Institute
      </h3>
      <p>
        <b>Wegener Center for Climate and Global Change</b>
        <br />
        <b>University of Graz</b>
        <br />
        Address: Brandhofgasse 5, 8010 Graz, Austria
        <br />
        Mail: wegcenter@uni-graz.at
        <br />
        Phone: +43-316-380 8470
      </p>
      <h3 style={{ color: "#558335" }}>
        Graz Climate Change Indicators Core Team
      </h3>
      <p>
        Gottfried Kirchengast (GCCI Project Leader & Scientific Lead)
        <br />
        Moritz Pichler (GCCI Project Scientist)
        <br />
        <br />
        For more contact details see the{" "}
        <Link href={"https://wegcenter.uni-graz.at/en/gcci/team/"}>
          GCCI Team page
        </Link>
        .
      </p>
    </div>
  )
}

const DeContact: React.FC = () => {
  return (
    <div style={{ height: "68vh" }}>
      <h3 style={{ color: "#558335" }}>
        Graz Climate Change Indicators Kerninstitut
      </h3>
      <p>
        <b>Wegener Center für Klima und Globalen Wandel</b>
        <br />
        <b>Universität Graz</b>
        <br />
        Adresse: Brandhofgasse 5, 8010 Graz, Austria
        <br />
        Mail: wegcenter@uni-graz.at
        <br />
        Telefon: +43-316-380 8470
      </p>
      <h3 style={{ color: "#558335" }}>
        Graz Climate Change Indicators Kernteam
      </h3>
      <p>
        Gottfried Kirchengast (GCCI Projektleiter & Wissenschaftliche Leitung)
        <br />
        Moritz Pichler (GCCI-Projektwissenschaftler)
        <br />
        <br />
        Für Kontaktdetails besuchen Sie die{" "}
        <Link href={"https://wegcenter.uni-graz.at/de/gcci/team/"}>
          GCCI Team Seite
        </Link>
        .
      </p>
    </div>
  )
}

export default Contact
